import React, { useState } from 'react';
import './galary.css';
import item0 from '../../Assets/images/REMOVAL/DSC_4537 1.jpg';
import item1 from '../../Assets/images/REMOVAL/DSC_4537.jpg';
import item2 from '../../Assets/images/REMOVAL/DSC_4542 1.jpg';
import item3 from '../../Assets/images/REMOVAL/DSC_4542.jpg';
import item4 from '../../Assets/images/REMOVAL/DSC_4547 1.jpg';
import item5 from '../../Assets/images/REMOVAL/DSC_4547.jpg';
import item6 from '../../Assets/images/REMOVAL/DSC_4552 1.jpg';
import item7 from '../../Assets/images/REMOVAL/DSC_4552.jpg';
import item8 from '../../Assets/images/REMOVAL/IMG_0090 1.jpg';
import item9 from '../../Assets/images/REMOVAL/IMG_0091.jpg';
import ImageModal from '../ImageModel/ImageModel';

const ItemRemoval = () => {
  const images = [item0, item1, item2, item3, item4, item5, item6, item7, item8, item9];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Flash Editing Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default ItemRemoval;
