import React, { useState } from 'react';
import './Navbar.css';
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import { Link } from 'react-router-dom'; // Import Link
import img from '../../Assets/logo2.png';

const Navbar = () => {
  const [active, setActive] = useState('navBar');

  const showNav = () => {
    setActive('navBar activeNavbar');
  };

  const removeNav = () => {
    setActive('navBar');
  };

  const [transparent, setTransparent] = useState('header');

  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent('header activeHeader');
    } else {
      setTransparent('header');
    }<li className="navItem">
              <button className='btn loginBtn'>
                <Link to="/" className="navLink">Home</Link> {/* Update for Gallery */}
              </button>
            </li>
  };

  window.addEventListener('scroll', addBg);

  return (
    <section className='navBarSection'>
      <div className={transparent}>
        <div className="logoDiv">
          <Link to="/" className="logo">
            <img src={img} alt="Logo" className="logoImage" />
          </Link>
        </div>

        <div className={active}>
          <ul className="navLists flex">
          <li className="navItem">
              <button className='btn loginBtn'>
                <Link to="/" className="navLink">Home</Link> {/* Update for Gallery */}
              </button>
            </li>
            <li className="navItem">
              <button className='btn loginBtn'>
                <Link to="/contact" className="navLink">Contact</Link>
              </button>
            </li>
            <li className="navItem">
              <button className='btn loginBtn'>
                <Link to="/about" className="navLink">About Us</Link>
              </button>
            </li>
            <li className="navItem">
              <button className='btn loginBtn'>
                <Link to="/service" className="navLink">Service</Link> {/* Link to Service Page */}
              </button>
            </li>
          </ul>

          <div onClick={removeNav} className="closeNavBar">
            <AiFillCloseCircle className='icon' />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className='icon' />
        </div>
      </div>
    </section>
  );
};

export default Navbar;
