import React, { useState } from 'react';
import './galary.css';
import dtd0 from '../../Assets/images/Day to dusk/DSC_8177.jpg';
import dtd1 from '../../Assets/images/Day to dusk/DSC_8592.jpg';
import dtd2 from '../../Assets/images/Day to dusk/DSC_8772.jpg';
import dtd3 from '../../Assets/images/Day to dusk/Output061.jpg';
import dtd4 from '../../Assets/images/Day to dusk/TCS01345_.jpg';
import dtd5 from '../../Assets/images/Day to dusk/night.jpg';

import ImageModal from '../ImageModel/ImageModel';

const Daytodusk = () => {
  const images = [dtd0, dtd1, dtd2, dtd3, dtd4, dtd5];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Flash Editing Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Daytodusk;
