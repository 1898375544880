import React from 'react';
import './About.css'; // CSS styling for AboutUs page
import { FaCogs, FaComments, FaDollarSign, FaHeadset } from 'react-icons/fa'; // Importing icons

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <h1>About Imagegerfinement</h1>
      <p>
        Imagegerfinement has been providing top-notch real estate editing services for over two years. 
        Our team of experienced professionals specializes in various editing services, ensuring the highest 
        quality results for all our clients.
      </p>

      <h2>Why Should You Hire Us?</h2>

      <div className="about-grid">
        <div className="about-point">
          <FaCogs className="about-icon" /> {/* Icon for Process */}
          <div className="about-content">
            <h3>1. Process</h3>
            <p>
              Our streamlined process involves three key stages: organizing images, editing them for quality, 
              and final quality checks to ensure everything meets your specific requirements. We focus on 
              efficiency at every step.
            </p>
          </div>
        </div>

        <div className="about-point">
          <FaComments className="about-icon" /> {/* Icon for Communication */}
          <div className="about-content">
            <h3>2. Communication</h3>
            <p>
              We prioritize clear communication with our clients. All official communication is handled 
              through email, and payments are made securely via Payoneer, ensuring a smooth and organized 
              workflow throughout the project.
            </p>
          </div>
        </div>

        <div className="about-point">
          <FaDollarSign className="about-icon" /> {/* Icon for Pricing */}
          <div className="about-content">
            <h3>3. Pricing</h3>
            <p>
              Our flexible pricing is based on the quality, quantity, and time needed for your project. We 
              strive to offer competitive rates while maintaining high standards in all our services and 
              deliverables.
            </p>
          </div>
        </div>

        <div className="about-point">
          <FaHeadset className="about-icon" /> {/* Icon for Support */}
          <div className="about-content">
            <h3>4. Support</h3>
            <p>
              Our support team is available 24/7 to assist with any queries or concerns. We are committed 
              to providing a smooth and satisfactory experience, ensuring you receive timely responses to all 
              your needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
