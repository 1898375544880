import React, { useState } from 'react';
import './galary.css';
import Vstaging0 from '../../Assets/images/Virtual Staging/01_I.jpeg';
import Vstaging1 from '../../Assets/images/Virtual Staging/01_II.jpg';
import Vstaging2 from '../../Assets/images/Virtual Staging/02_I.jpeg';
import Vstaging3 from '../../Assets/images/Virtual Staging/02_II.jpg';
import Vstaging4 from '../../Assets/images/Virtual Staging/03_I.jpg';
import Vstaging5 from '../../Assets/images/Virtual Staging/03_II.jpg';
import Vstaging6 from '../../Assets/images/Virtual Staging/04_I.jpg';
import Vstaging7 from '../../Assets/images/Virtual Staging/04_II.jpg';
import Vstaging8 from '../../Assets/images/Virtual Staging/05_I.jpg';
import Vstaging9 from '../../Assets/images/Virtual Staging/05_II.jpg';
import Vstaging10 from '../../Assets/images/Virtual Staging/06_II.jpg';
import Vstaging11 from '../../Assets/images/Virtual Staging/07_II.jpg';

import ImageModal from '../ImageModel/ImageModel';

const Vstaging = () => {
  const images = [Vstaging0,Vstaging1 ,Vstaging2,Vstaging3,Vstaging4,Vstaging5,Vstaging6,Vstaging7,Vstaging8,Vstaging9,Vstaging10,Vstaging11];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Flash Editing Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default Vstaging;
