import React, { useRef, useState } from 'react';
import './contact.css'; // Import the CSS file
import emailjs from '@emailjs/browser';

const Contact = () => {
  const formRef = useRef(); // Use the formRef here
  const [formData, setFormData] = useState({ from_name: '', user_email: '', message: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // For validation errors

  // Email validation function
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (!validateEmail(formData.user_email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    if (!formData.from_name || !formData.message) {
      setErrorMessage('All fields are required.');
      return;
    }

    // Clear the error message if no errors
    setErrorMessage('');

    // Send the email via EmailJS
    emailjs
      .sendForm('service_5lncmrk', 'template_qmv5wap', formRef.current, 'GvCYEPISjP4gzvveh')
      .then(
        (response) => {
          alert('Your message has been sent successfully!');
          setFormSubmitted(true);
          formRef.current.reset(); // Reset the form fields after submission
          setFormData({ from_name: '', user_email: '', message: '' }); // Clear the form state
        },
        (error) => {
          console.log('FAILED to send email...', error.text);
          alert('Failed to send your message, please try again later.');
        }
      );
  };

  return (
    <section className="contact-section" id="contactus">
      <div className="contact-right">
        <div className="contact-form">
          <h1>Contact Us</h1>

          {/* Success and Error Messages */}
          {formSubmitted && <p className="success-message">Message sent successfully!</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          {/* Contact Form */}
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              type="text"
              name="from_name"
              placeholder="Your name"
              value={formData.from_name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="user_email"
              placeholder="Your email"
              value={formData.user_email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>

      {/* Additional Sections for Services */}
      <div className="service-details">
        <div id="book-demo">
          <h3>Book a Demo</h3>
          <p>Schedule a personalized demo to see how our services can benefit you.</p>
        </div>
        <div id="asking-price">
          <h3>Asking Price</h3>
          <p>Contact us to get a quote for our services tailored to your needs.</p>
        </div>
        <div id="become-partner">
          <h3>Become a Partner</h3>
          <p>Join us as a partner and unlock exclusive benefits.</p>
        </div>
        <div id="service-list">
          <h3>Service List Categories</h3>
          <p>Explore our wide range of services:</p>
          <ul>
            <li>Real Estate Editing</li>
            <li>Photo Enhancement</li>
            <li>Custom Image Services</li>
            <li>And more...</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Contact;
