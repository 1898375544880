import React from 'react';
import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="logo-container">
                    <hr className="golden-line" />
                </div>
                <div className="social-media">
                    <a href="mailto:info.imagerefiment@gmail.com" target="_blank" rel="noopener noreferrer">
                        <i className="fas fa-envelope"></i> {/* Email icon */}
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61567654620549&sk=grid" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/imagerefinement/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                    </a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} 9TEEN'M - All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
