import React from 'react';
import './Home.css';
import img from '../../Assets/nesss-removebg-preview.png'

export default function Home() {
  return (
    <div className="home-container" style={{ background: 'linear-gradient(#FF5F6D, #FFC371)', minHeight: '100vh' }}>
      <div className="content-section">
        <h1> Real Estate Image Editing !!!</h1>
        <p>
          Enhance your real estate images with professional editing services. 
        </p>
        {/* <button className="explore-btn">Let's Start Exploring</button> */}
      </div>
      <div className="image-section">
        {/* Replace the src with your actual image */}
        <img src={img} alt="Real Estate Editing" />
      </div>
    </div>
  );
}
