import React, { useState } from 'react';
import './galary.css';
import singel0 from '../../Assets/images/Single image/1.jpg';
import singel1 from '../../Assets/images/Single image/10.jpg';
import singel2 from '../../Assets/images/Single image/11.jpg';
import singel3 from '../../Assets/images/Single image/19.jpg';
import singel4 from '../../Assets/images/Single image/2.jpg';
import singel5 from '../../Assets/images/Single image/22.jpg';
import singel6 from '../../Assets/images/Single image/23.jpg';
import singel7 from '../../Assets/images/Single image/3.jpg';
import singel8 from '../../Assets/images/Single image/5.jpg';
import ImageModal from '../ImageModel/ImageModel';

const SingleGallery = () => {
  const images = [singel0, singel1, singel2, singel3, singel4, singel5, singel6, singel7, singel8];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ background: 'linear-gradient(#1cefff,#1cefff)', minHeight: '100vh' }}>
    <div className="gallery-section" >
      <h2>Flash Editing Gallery</h2>
      <div className="gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Flash ${index + 1}`} onClick={() => openModal(index)} />
          </div>
        ))}
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        image={images[currentImageIndex]}
        onNext={nextImage}
        onPrev={prevImage}
      />
    </div>
    </div>
  );
};

export default SingleGallery;
