import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Service from './Components/Services/Service';
import FlashGallery from './Components/Galary/Flash';
import SingleGallery from './Components/Galary/Single';
import Daytodusk from './Components/Galary/Daytodusk';
import Hdr from './Components/Galary/Hdr';
import ItemRemoval from './Components/Galary/Item';
import Twlight from './Components/Galary/Twlight';
import Vstaging from './Components/Galary/Vstaging'
import About from './Components/About/About';
import Contact from './Components/ContactUs/contact';
import Footer from './Components/Footer/Footer'

function App() {
  return (
    <Router>
        <Navbar />
        {/* Routes for dynamic gallery pages */}
        <Routes>
          {/* Static pages at the root path */}
          <Route path="/" element={<MainContent />} />
          <Route path="/gallery/flash" element={<FlashGallery />} />
          <Route path="/gallery/single" element={<SingleGallery />} />
          <Route path="/gallery/daytodusk" element={<Daytodusk />} />
          <Route path="/gallery/hdr" element={<Hdr />} />
          <Route path="/gallery/itemRemoval" element={<ItemRemoval />} />
          <Route path="/gallery/twlight" element={<Twlight />} />
          <Route path="/gallery/vstaging" element={<Vstaging />} />
          <Route path="/service" element={<Service />} /> 
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          
        </Routes>
    </Router>
  );
}

// Main content component for static pages
const MainContent = () => {
  return (
    <>
      <Home />
      <Service />
      <About/>
      <Contact/>
      <Footer/>
    </>
  );
};

export default App;
